import { all, takeEvery } from "redux-saga/effects";

import { ServiceContainer } from "../../app/serviceContainer";

import { loadStockReplenishmentOrderLinesSaga } from "./sagas/loadStockReplenishmentOrderLinesSaga";
import { stockReplenishmentOrderLinesRequired } from "./stockReplenishmentSlice";

export default function* stockReplenishmentSagas(
  serviceContainer: ServiceContainer
) {
  yield all([
    takeEvery(
      stockReplenishmentOrderLinesRequired.type,
      loadStockReplenishmentOrderLinesSaga,
      serviceContainer
    ),
  ]);
}
