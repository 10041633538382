import { useTranslation } from "@ahlsell-group/app-localization";
import { InventoryLocationItemInfo } from "@ahlsell-group/store20-inventory-location-service";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import typescriptAssertNever from "../../../util/typescriptAssertNever";
import ErrorModal from "../../error/components/ErrorModal";
import { AppLoadingPage } from "../../page/components/AppLoadingPage";
import { ViewComponentProps } from "../../routing/components/View";
import routes from "../../routing/routes";
import useNavigate from "../../routing/useNavigate";
import { selectInventoryLocationNewItemState } from "../inventoryLocationSelectors";
import { itemAdded } from "../inventoryLocationSlice";

import {
  InventoryLocationSharedItem,
  InventoryLocationSharedItemButtonProps,
} from "./InventoryLocationItemPage";

const InventoryLocationNewItem: React.FC<ViewComponentProps> = function () {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const itemState = useSelector(selectInventoryLocationNewItemState);

  const addItem = (item: InventoryLocationItemInfo) => {
    dispatch(itemAdded(item));
    navigate.back();
  };

  const buttonProps: InventoryLocationSharedItemButtonProps = {
    "data-cy": "InventoryLocationSharedItem-add",
    text: t("inventoryLocation.add"),
    onClick: addItem,
  };

  useEffect(() => {
    if (itemState.type === "none") {
      navigate(routes.inventoryLocation.change, {}, { replace: true });
    }
  }, [itemState, navigate]);

  if (itemState.type === "none") {
    throw new Error("[internal error] Did not get a new item to add");
  }

  if (itemState.type === "error") {
    return (
      <ErrorModal
        data-cy="inventory-location-error"
        data-cy-error-reason={itemState.error.reason}
        category="inventoryLocationItemSearch"
        error={itemState.error}
        onClose={() => navigate.back()}
      />
    );
  }

  if (itemState.type === "loading") {
    return (
      <AppLoadingPage onCancel={() => navigate.back()}>
        {t("inventoryLocation.loadingItem")}
      </AppLoadingPage>
    );
  }

  if (itemState.type === "loaded") {
    const { item } = itemState;
    return <InventoryLocationSharedItem item={item} button={buttonProps} />;
  }

  typescriptAssertNever(itemState);
  return null;
};

export default InventoryLocationNewItem;
