import { ConfigManager } from "@ahlsell-group/store20-service-core";
import { createRoot } from "react-dom/client";
import { Store } from "redux";

import { DebugObject } from "../features/debug";
import { State } from "../types";

import { appReadyToStart } from "./appSlice";
import Root from "./components/Root";
import getConfig from "./config";
import createSaga from "./redux/createSaga";
import createStore from "./redux/createStore";
import * as ServiceContainer from "./serviceContainer";

export type StartStoreFn = () => void;
export type RenderAppFn = () => void;

export function loadConfig(): BootstrapConfig {
  return getConfig();
}

export function createServiceContainer(
  configManager: ConfigManager<BootstrapConfig>
): ServiceContainer.ServiceContainer {
  const serviceContainer =
    ServiceContainer.createServiceContainer(configManager);
  return serviceContainer;
}

export function updateTelemetryViewportOnResize(
  serviceContainer: ServiceContainer.ServiceContainer
) {
  window.addEventListener("resize", () => {
    serviceContainer.appTelemetryService.updateSessionInfo({
      viewport: [window.innerWidth, window.innerHeight],
    });
  });
}

export async function localization(
  serviceContainer: ServiceContainer.ServiceContainer
) {
  const appConfig = await serviceContainer.appConfigService.getAppConfig();
  serviceContainer.localizationService.initialize(appConfig.selectedLanguage);
}

export async function redux(
  configManager: ConfigManager<BootstrapConfig>,
  serviceContainer: ServiceContainer.ServiceContainer
): Promise<[store: Store<State>, startStore: StartStoreFn]> {
  const [sagaMiddleware, runSaga] = createSaga(serviceContainer);
  const store = await createStore(sagaMiddleware, serviceContainer);
  serviceContainer.storeAccessor.setStore(store);

  const startStore = () => {
    runSaga(serviceContainer);
    store.dispatch(
      appReadyToStart({
        bootstrapConfig: serviceContainer.configManager.getConfig(),
        initialLanguage:
          serviceContainer.localizationService.getCurrentLanguage(),
      })
    );
  };

  return [store, startStore];
}

export function createAppRoot(
  store: Store,
  serviceContainer: ServiceContainer.ServiceContainer
): RenderAppFn {
  const container = document.getElementById("app-root");
  const root = createRoot(container!);

  return () => root.render(Root(store, serviceContainer));
}

export function assignServicesToDebugObject(
  serviceContainer: ServiceContainer.ServiceContainer,
  store: Store<State>,
  debugObject: DebugObject
) {
  debugObject.serviceContainer = serviceContainer;
  debugObject.store = store;
}

/**
 * Sometimes stored key-value pairs are removed from the Ace app. In those cases,
 * we want to remove the items from the users' storage too.
 */
export async function removeUnusedLocallyStoredItems(
  serviceContainer: ServiceContainer.ServiceContainer
) {
  const keys = await serviceContainer.storageService.getKeys();
  const keysToRemove = keys.filter(
    (key) =>
      // Key for storing a manual stock-take in the legacy flow.
      key.includes("s20_manual_inventory_") ||
      // Key for storing submitted stock-takes in the legacy flow.
      key.includes("s20_submitted_stock_takes_")
  );

  await Promise.all([
    serviceContainer.storageService.remove({ key: "userLanguage" }),
    serviceContainer.storageService.remove({
      key: "hasSeenInventoryLocationCoachMark",
    }),
    ...keysToRemove.map((key) =>
      serviceContainer.storageService.remove({ key })
    ),
  ]);
}
