import { GetRouteSubTree, createRoutes } from "../routing/utils";

const itemSearchRoutes = createRoutes({
  itemSearch: {
    path: "/item-search?mode&cameraError?searchTerm",
  },
} as const);

export default itemSearchRoutes;
export type ItemSearchRoutes = GetRouteSubTree<typeof itemSearchRoutes>;
