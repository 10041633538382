import { IStockTakeSubmission } from "@ahlsell-group/store20-stock-taking-service";
import { actions } from "redux-router5";
import { call, put, select } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import ActionType from "../../../util/ActionType";
import toErrorActionPayload from "../../../util/toErrorActionPayload";
import routes from "../../routing/routes";
import { selectWarehouseId } from "../../warehouse/warehouseSelectors";
import {
  submitStockTakesFailed,
  submitStockTakesRequested,
  submitStockTakesSucceeded,
} from "../manualStockTakingSlice";

import { getCurrentStockTakeId } from "./getCurrentStockTakeId";

export function* submitStockTakeSaga(
  serviceContainer: ServiceContainer,
  action: ActionType<typeof submitStockTakesRequested>
): Saga {
  const { stockTakingService, handleSagaError } = serviceContainer;

  try {
    const stockId: number = yield select(selectWarehouseId);
    const stockTakeId: string = yield call(
      getCurrentStockTakeId,
      serviceContainer
    );

    const submission: IStockTakeSubmission = yield call(
      [stockTakingService, "submitStockTake"],
      stockId,
      stockTakeId,
      action.payload.enableAutomaticCompletion
    );

    yield put(submitStockTakesSucceeded());

    yield put(
      actions.navigateTo(
        routes.stockTaking.route,
        { highlightVivaldiId: submission.vivaldiId.toString() },
        { replace: true }
      )
    );
  } catch (e) {
    yield* handleSagaError(
      (errorCode) => `saga:stock-taking:submitStockTakeSaga:${errorCode}`,
      e,
      put(submitStockTakesFailed(toErrorActionPayload(e)))
    );

    yield put(
      actions.navigateTo(
        routes.stockTaking.manual.submit.error.route,
        {},
        { replace: true }
      )
    );
  }
}
