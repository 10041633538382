import { useTranslation } from "@ahlsell-group/app-localization";
import { AceChip } from "@ahlsell-group/app-ui/AceChip";
import { StockTake } from "@ahlsell-group/store20-stock-taking-service";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import classNames from "classnames";
import React from "react";

import RouteLink from "../../routing/components/RouteLink";
import routes from "../../routing/routes";
import { getDate, getTime, isToday } from "../util/date";
import { doesStockTakeHaveProblem } from "../util/doesStockTakeHaveProblem";

interface StockTakeSubmissionListItemProps {
  submission: StockTake;
  highlight: boolean;
}

const StockTakeSubmissionListItem = React.forwardRef<
  HTMLDivElement,
  StockTakeSubmissionListItemProps
>(({ submission, highlight }, ref) => {
  const { t } = useTranslation("common");
  const { stockTakeId, submittedDateTimeUtc, vivaldiId, items } = submission;

  const submissionDiff = items.some((x) => x.quantityDiff);
  const submissionProblem = doesStockTakeHaveProblem(submission);

  const today = submittedDateTimeUtc && isToday(submittedDateTimeUtc);

  return (
    <RouteLink
      route={routes.stockTaking.submitted}
      routeParams={{ stockTakeId, tab: "diff" }}
      variant="raw"
      className={classNames(
        "bg-theme-secondary-bg-50 pl-2 pr-4 mb-2 rounded block relative border-l-8",
        [
          submissionProblem && "border-l-theme-status-text-error",
          submissionDiff && "border-l-theme-status-text-attention",
          "border-l-transparent",
        ].find(Boolean)
      )}
      data-cy={`StockTakeSubmissionListItem-${stockTakeId} ${
        submissionProblem ? "StockTakeSubmissionListItem-problem" : ""
      }`}
    >
      <div ref={ref}>
        {today && (
          <AceChip
            color="success"
            wiggle={highlight}
            className="absolute -right-1 -top-2 mt-px"
          >
            {t("stockTakingReview.new")}
          </AceChip>
        )}
        <div className="flex items-center py-1">
          <Typography
            variant="body"
            component="span"
            className="grow"
            data-cy="StockTakenSubmission-submission-id"
          >
            #{vivaldiId}
          </Typography>
          <div className="flex flex-col text-right">
            <Typography variant="body-sm" className="flex-1" component="span">
              {submittedDateTimeUtc && getTime(submittedDateTimeUtc)}
            </Typography>
            <Typography
              variant="body-sm"
              component="span"
              color="gray"
              className="flex-1"
            >
              {submittedDateTimeUtc &&
                (today ? t("today") : getDate(submittedDateTimeUtc))}
            </Typography>
          </div>
        </div>
      </div>
    </RouteLink>
  );
});

export default StockTakeSubmissionListItem;
