import { Trans, useTranslation } from "@ahlsell-group/app-localization";
import { PageSection } from "@ahlsell-group/app-ui/PageSection";
import { PageSubheadline } from "@ahlsell-group/app-ui/PageSubheadline";
import { ProgressDots } from "@ahlsell-group/app-ui/ProgressDots";
import {
  CircleExclamationIcon,
  PlusIcon,
} from "@ahlsell-group/ui-kit-imagery-react";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Alert } from "@ahlsell-group/ui-kit/feedback";
import { Fab } from "@ahlsell-group/ui-kit/inputs";
import { Center } from "@ahlsell-group/ui-kit/layout";
import { LinkButton } from "@ahlsell-group/ui-kit/navigation";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useScrollPosition } from "../../../util/useScrollPosition";
import ErrorPageSection from "../../error/components/ErrorPageSection";
import { AppPage } from "../../page/components/AppPage";
import { ViewComponentProps } from "../../routing/components/View";
import routes from "../../routing/routes";
import useNavigate from "../../routing/useNavigate";
import {
  selectInitStockTakeError,
  selectIsLoadingManualStockTake,
  selectItems,
  selectManualStockTakingDeleteItemError,
  selectManualStockTakingDeleteItemState,
  selectHasReviewValidationError,
  selectReviewValidationErrors,
} from "../manualStockTakingSelectors";
import { initStockTake, reviewStockTake } from "../manualStockTakingSlice";

import ManualStockTakingItemDeleteErrorModal from "./ManualStockTakingItemDeleteErrorModal";
import ManualStockTakingListItem from "./ManualStockTakingListItem";
import ManualStockTakingLoadingModal from "./ManualStockTakingLoadingModal";
import { ManualStockTakingReviewModal } from "./ManualStockTakingReviewModal";

type ManualStockTakingListParams = {
  modal?: "review";
  goto?: "scanner" | "itemSearch";
};

const ManualStockTakingList: React.FC<
  ViewComponentProps<ManualStockTakingListParams>
> = function ({ params }) {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const items = useSelector(selectItems);
  const initError = useSelector(selectInitStockTakeError);
  const isLoading = useSelector(selectIsLoadingManualStockTake);
  const scrollContainerRef = useScrollPosition("ManualStockTakingList");
  const isDeleting = useSelector(selectManualStockTakingDeleteItemState);
  const deleteError = useSelector(selectManualStockTakingDeleteItemError);
  const reviewValidationError = useSelector(selectHasReviewValidationError);
  const validationErrors = useSelector(selectReviewValidationErrors);
  const [scrollRef, setScrollRef] = useState<HTMLDivElement | null>(null);

  const sortedItems = validationErrors
    ? [...items].sort(
        (a, b) =>
          Number(b.itemId in validationErrors) -
          Number(a.itemId in validationErrors)
      )
    : items;

  useEffect(() => {
    if (params.goto === "scanner") {
      // remove GoTo
      navigate(routes.stockTaking.manual, {}, { replace: true });
      navigate(routes.stockTaking.manual.scan);
    }

    if (params.goto === "itemSearch") {
      navigate(routes.stockTaking.manual, {}, { replace: true });
      navigate(routes.stockTaking.manual.scan);
      navigate(routes.stockTaking.manual.itemSearch);
    }
  }, [navigate, params.goto]);

  useEffect(() => {
    if (!reviewValidationError || !scrollRef) return;

    scrollRef.scrollTo(0, 0);
  }, [reviewValidationError, scrollRef]);

  return (
    <AppPage
      data-cy="ManualStockTakingList"
      headline={
        <PageSubheadline
          onBack={() => navigate.back()}
          back-data-cy="ManualStockTakingList-back"
          actions={
            items.length ? (
              <LinkButton
                onClick={() => {
                  navigate(routes.stockTaking.manual, { clear: null });
                }}
                data-cy="ManualStockTakingList-clear"
              >
                {t("stockTaking.clear")}
              </LinkButton>
            ) : undefined
          }
        >
          {t("stockTaking.stockTaking")}
        </PageSubheadline>
      }
    >
      {initError && (
        <Alert severity="error" className="m-4 flex flex-col gap-2">
          <ErrorPageSection category="stockTakeInit" error={initError} />
          <p>
            <LinkButton
              onClick={() => {
                dispatch(initStockTake());
              }}
              data-cy="ManualStockTakingList-initError-tryAgain"
              className="-ml-2"
            >
              {t("tryAgain")}
            </LinkButton>
          </p>
        </Alert>
      )}
      {/* eslint-disable-next-line no-nested-ternary */}
      {isLoading ? (
        <Center>
          <ProgressDots data-cy="ManualStockTakingList-loader" />
        </Center>
      ) : items.length === 0 ? (
        <Center
          className="mx-14 text-center"
          data-cy="ManualStockTakingList-empty"
        >
          <Typography variant="body-sm">
            {t("stockTaking.startStockTakingMessage")}
          </Typography>
        </Center>
      ) : (
        <div
          className="overflow-auto h-0 grow px-4 pb-20"
          // Use `will-change:z-index` as a workaround for bug on iOS 17.
          // https://bugs.webkit.org/show_bug.cgi?id=262951
          style={{ willChange: "z-index" }}
          data-cy="ManualStockTakingList-scroll-container"
          ref={(el) => {
            scrollContainerRef(el);
            setScrollRef(el);
          }}
        >
          {reviewValidationError && (
            <Alert
              className="mb-4"
              severity="error"
              data-cy="ManualStockTakingList-review-error"
              icon={CircleExclamationIcon}
            >
              <Typography variant="body-sm">
                <Trans
                  t={t}
                  i18nKey="stockTakingReview.itemsInNeedOfAttention"
                  count={
                    validationErrors && Object.keys(validationErrors).length
                  }
                />
              </Typography>
            </Alert>
          )}
          {sortedItems.map((item) => (
            <ManualStockTakingListItem
              key={item.itemId}
              item={item}
              error={validationErrors?.[item.itemId]}
              onSelect={() =>
                navigate(routes.stockTaking.manual.item, {
                  itemIdOrBarcode: item.itemId,
                  acceptQuantity: item.stockTakingQuantity,
                })
              }
            />
          ))}
        </div>
      )}
      <div className="absolute left-0 bottom-0 w-full">
        <PageSection>
          <div className="flex gap-2">
            {items.length !== 0 && (
              <Fab
                size="large"
                className="grow"
                onClick={() => {
                  dispatch(reviewStockTake());
                }}
                data-cy="ManualStockTakingList-review"
                disabled={Boolean(isLoading || initError)}
              >
                {t("stockTaking.review", { count: items.length })}
              </Fab>
            )}
            <Fab
              data-cy="new-item"
              variant="primary"
              size="large"
              className="grow"
              icon={PlusIcon}
              onClick={() => navigate(routes.stockTaking.manual.scan)}
              disabled={Boolean(isLoading || initError)}
            >
              {t("stockTaking.newItem")}
            </Fab>
          </div>
        </PageSection>
      </div>
      {params.modal === "review" && <ManualStockTakingReviewModal />}
      {isDeleting === "in-progress" && (
        <ManualStockTakingLoadingModal
          headerText={t("stockTaking.updatingStockTake")}
          detailText={t("stockTaking.deletingItemFromStockTake")}
          onClose={() => navigate.back()}
        />
      )}
      {isDeleting === "idle" && deleteError && (
        <ManualStockTakingItemDeleteErrorModal error={deleteError} />
      )}
    </AppPage>
  );
};

export default ManualStockTakingList;
