import { useTranslation } from "@ahlsell-group/app-localization";
import { InventoryLocationItemInfo } from "@ahlsell-group/store20-inventory-location-service";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ViewComponentProps } from "../../routing/components/View";
import routes from "../../routing/routes";
import useNavigate from "../../routing/useNavigate";
import { selectInventoryLocationItems } from "../inventoryLocationSelectors";
import { removeItem } from "../inventoryLocationSlice";

import {
  InventoryLocationSharedItem,
  InventoryLocationSharedItemButtonProps,
} from "./InventoryLocationItemPage";

const InventoryLocationItem: React.FC<ViewComponentProps> = function ({
  params,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const items = useSelector(selectInventoryLocationItems);

  const itemId = params?.itemId;

  if (typeof itemId !== "string") {
    navigate.back();
    throw new Error("Missing params.itemId");
  }

  const itemInList = items.find((x) => x.itemId === itemId);

  // The correct item should always be available. If it isn't, the user has
  // probably navigated to a page where there is a deleted item.
  useEffect(() => {
    if (!itemInList) {
      navigate(routes.inventoryLocation.change, {}, { replace: true });
    }
  }, [itemInList, navigate]);

  if (!itemInList) {
    return null;
  }

  const handleRemove = async (item: InventoryLocationItemInfo) => {
    await navigate.back();
    dispatch(removeItem({ itemId: item.itemId }));
  };

  const buttonProps: InventoryLocationSharedItemButtonProps = {
    "data-cy": "InventoryLocationSharedItem-remove",
    text: t("remove"),
    onClick: handleRemove,
  };

  return <InventoryLocationSharedItem item={itemInList} button={buttonProps} />;
};

export default InventoryLocationItem;
