import { all, takeEvery, takeLatest } from "redux-saga/effects";

import { appStarted } from "../../app/appSlice";
import { ServiceContainer } from "../../app/serviceContainer";

import { currentCoachMark, setCoachMark } from "./homeSlice";
import coachMarkSaga from "./sagas/coachMarkSaga";
import dismissCoachMarkSaga from "./sagas/dismissCoachMarkSaga";
import updateIosAlertSaga from "./sagas/updateIosAlertSaga";

export default function* homeSagas(serviceContainer: ServiceContainer) {
  yield all([
    takeLatest(appStarted.type, updateIosAlertSaga),
    takeLatest(
      appStarted.type,
      coachMarkSaga,
      currentCoachMark,
      serviceContainer
    ),
    takeEvery(setCoachMark.type, dismissCoachMarkSaga, serviceContainer),
  ]);
}
