import { GenericOperationError } from "@ahlsell-group/store20-service-core";
import { StockReplenishmentOrderLine } from "@ahlsell-group/store20-stock-replenishment-service";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface StockReplenishmentOrderLinesKey {
  stockId: number;
  itemId: string;
}

export interface StockReplenishmentOrderLinesLoadedPayload {
  key: StockReplenishmentOrderLinesKey;
  data: StockReplenishmentOrderLine[];
  loadedAt: string;
}

export interface StockReplenishmentOrderLinesLoadFailedPayload {
  key: StockReplenishmentOrderLinesKey;
  reason: GenericOperationError;
}

const name = "stockReplenishment";

export type StockReplenishmentOrderLinesWithState =
  | { state: "idle"; data: StockReplenishmentOrderLine[]; loadedAt: string }
  | { state: "loading"; data?: StockReplenishmentOrderLine[] }
  | { state: "error"; data?: undefined; reason: GenericOperationError };

export interface StockReplenishmentState {
  orderLines: Record<string, StockReplenishmentOrderLinesWithState | undefined>;
}

export const stringifyStockReplenishmentOrderLinesKey = (
  key: StockReplenishmentOrderLinesKey
) => `${key.stockId}-${key.itemId}`;

const initialState: StockReplenishmentState = {
  orderLines: {},
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    stockReplenishmentOrderLinesRequired(
      state,
      { payload }: PayloadAction<StockReplenishmentOrderLinesKey>
    ) {
      state.orderLines[stringifyStockReplenishmentOrderLinesKey(payload)] = {
        state: "loading",
        data: state.orderLines[
          stringifyStockReplenishmentOrderLinesKey(payload)
        ]?.data,
      };
    },
    stockReplenishmentOrderLinesLoaded(
      state,
      { payload }: PayloadAction<StockReplenishmentOrderLinesLoadedPayload>
    ) {
      const { key, data, loadedAt } = payload;
      state.orderLines[stringifyStockReplenishmentOrderLinesKey(key)] = {
        state: "idle",
        loadedAt,
        data,
      };
    },
    stockReplenishmentOrderLinesLoadFailed(
      state,
      { payload }: PayloadAction<StockReplenishmentOrderLinesLoadFailedPayload>
    ) {
      const { key, reason } = payload;
      state.orderLines[stringifyStockReplenishmentOrderLinesKey(key)] = {
        state: "error",
        reason,
      };
    },
  },
});

export const {
  reducer: stockReplenishmentReducer,
  actions: {
    stockReplenishmentOrderLinesRequired,
    stockReplenishmentOrderLinesLoaded,
    stockReplenishmentOrderLinesLoadFailed,
  },
} = slice;
