import { StockReplenishmentOrderLine } from "@ahlsell-group/store20-stock-replenishment-service";
import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import toErrorActionPayload from "../../../util/toErrorActionPayload";
import {
  stockReplenishmentOrderLinesLoaded,
  stockReplenishmentOrderLinesLoadFailed,
  StockReplenishmentOrderLinesKey,
} from "../stockReplenishmentSlice";

export function* loadStockReplenishmentOrderLinesSaga(
  { stockReplenishmentService, handleSagaError }: ServiceContainer,
  { payload: key }: PayloadAction<StockReplenishmentOrderLinesKey>
) {
  try {
    const result: StockReplenishmentOrderLine[] = yield call(
      [stockReplenishmentService, "getStockReplenishmentOrderLines"],
      key
    );
    yield put(
      stockReplenishmentOrderLinesLoaded({
        key,
        data: result,
        loadedAt: new Date().toISOString(),
      })
    );
  } catch (e) {
    yield call(
      handleSagaError,
      (errorCode) =>
        `saga:stock-replenishment:loadStockReplenishmentOrderLinesSaga:${errorCode}`,
      e,
      put(
        stockReplenishmentOrderLinesLoadFailed(toErrorActionPayload(e, { key }))
      )
    );
  }
}
