import { Page } from "@ahlsell-group/app-ui/Page";
import { PageSection } from "@ahlsell-group/app-ui/PageSection";
import { ProgressDots } from "@ahlsell-group/app-ui/ProgressDots";
import { ArrowLeftIcon } from "@ahlsell-group/ui-kit-imagery-react";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Button, IconButton } from "@ahlsell-group/ui-kit/inputs";
import { Center } from "@ahlsell-group/ui-kit/layout";
import { HtmlMetaThemeColor, useZIndex } from "@ahlsell-group/ui-kit/util";
import React from "react";

import useNavigate from "../../routing/useNavigate";

interface Action {
  "data-cy": string;
  text: string;
  primary?: boolean;
  disabled?: boolean;
}

export interface GettingStartedPageSectionProps {
  backButton: boolean;
  title?: string;
  actions?: Record<string, Action>;
  onAction?: (action: string) => void;
  "data-cy": string;
  loading?: boolean;
}

const GettingStartedPage: React.FC<
  React.PropsWithChildren<GettingStartedPageSectionProps>
> = function GettingStartedPageSection({
  backButton,
  title,
  actions,
  onAction,
  "data-cy": dataCy,
  children,
  loading = false,
}) {
  const { back } = useNavigate();
  const [zIndex] = useZIndex(loading);

  const headerSection =
    backButton || title ? (
      <PageSection>
        {backButton && (
          <IconButton
            data-cy="GettingStartedPageSection-back"
            icon={ArrowLeftIcon}
            variant="tertiary"
            size="large"
            rounded="full"
            onClick={() => back()}
          />
        )}
        {title && (
          <Typography variant="heading-h2" className="mt-6">
            {title}
          </Typography>
        )}
      </PageSection>
    ) : null;

  const actionSection = actions && (
    <PageSection backgroundColor="gray" className="!gap-2">
      {Object.entries(actions).map(
        ([
          actionId,
          { text, primary = false, disabled = false, "data-cy": buttonDataCy },
        ]) => (
          <Button
            variant={primary ? "primary" : "tertiary"}
            size="large"
            disabled={disabled}
            key={actionId}
            data-cy={buttonDataCy}
            onClick={() => onAction?.(actionId)}
          >
            {text}
          </Button>
        )
      )}
    </PageSection>
  );

  return (
    <Page backgroundColor="gray">
      <div className="flex flex-col grow">
        {headerSection}
        <PageSection data-cy={dataCy} className="basis-0 grow overflow-auto">
          {children}
        </PageSection>
        {actionSection}
      </div>
      {loading && (
        <HtmlMetaThemeColor color="backdrop-on-secondary-bg-200">
          <div className="fixed inset-0 bg-black/70" style={{ zIndex }}>
            <Center variant="center">
              <ProgressDots />
            </Center>
          </div>
        </HtmlMetaThemeColor>
      )}
    </Page>
  );
};

export default GettingStartedPage;
