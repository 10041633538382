import { useTranslation } from "@ahlsell-group/app-localization";
import { PageSection } from "@ahlsell-group/app-ui/PageSection";
import { InventoryLocationItemInfo } from "@ahlsell-group/store20-inventory-location-service";
import { ArrowLeftIcon } from "@ahlsell-group/ui-kit-imagery-react";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Fab, IconButton } from "@ahlsell-group/ui-kit/inputs";
import React from "react";
import { useSelector } from "react-redux";

import useItemImageUrl from "../../item-image/useItemImageUrl";
import { AppPage } from "../../page/components/AppPage";
import useNavigate from "../../routing/useNavigate";
import { selectWarehouseId } from "../../warehouse/warehouseSelectors";

import ItemLocation from "./ItemLocation";

export interface InventoryLocationSharedItemButtonProps {
  "data-cy": string;
  text: string;
  onClick: (item: InventoryLocationItemInfo) => void;
}

interface InventoryLocationSharedItemProps {
  item: InventoryLocationItemInfo;
  button: InventoryLocationSharedItemButtonProps;
}

export const InventoryLocationSharedItem: React.FC<InventoryLocationSharedItemProps> =
  function ({ item, button }) {
    const navigate = useNavigate();
    const { t } = useTranslation("common");
    const assetUrl = useItemImageUrl();
    const warehouseId = useSelector(selectWarehouseId);

    return (
      <AppPage backgroundColor="white" data-cy="InventoryLocationSharedItem">
        <PageSection>
          <IconButton
            className="absolute"
            variant="tertiary"
            rounded="full"
            size="large"
            icon={ArrowLeftIcon}
            data-cy="InventoryLocationSharedItem-back"
            onClick={() => navigate.back()}
          />

          <div className="mx-auto py-9 max-w-[11.25rem] max-h-[11.25rem]">
            <img
              className="object-contain w-full h-full"
              src={assetUrl(item.assetUrl)}
              alt=""
            />
          </div>

          <Typography
            data-cy="InventoryLocationSharedItem-itemId"
            className="mb-3"
            variant="heading-h2"
          >
            {t("inventoryLocation.itemIdShort")} {item.itemId}
          </Typography>
          <Typography variant="heading-h3" color="gray" className="mb-1">
            {item.name}
          </Typography>
          <Typography variant="body" color="gray" className="mb-6">
            {item.description1} {item.description2}
          </Typography>
          <Typography variant="body-sm" color="gray">
            <ItemLocation
              warehouseId={warehouseId}
              itemId={item.itemId}
              includeCurrentPrefix
            />
          </Typography>
        </PageSection>
        <div className="absolute left-0 bottom-0 w-full">
          <PageSection>
            <Fab
              data-cy={button["data-cy"]}
              variant="primary"
              size="large"
              onClick={() => button.onClick(item)}
            >
              {button.text}
            </Fab>
          </PageSection>
        </div>
      </AppPage>
    );
  };
