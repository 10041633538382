import { FeatureFlag } from "@ahlsell-group/store20-config-service";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface CoachMark {
  name: string;
  storageName: string;
  featureFlag: FeatureFlag | undefined;
}

export const currentCoachMark: CoachMark = {
  name: "StockTakingReview",
  storageName: "hasSeenStockTakingReviewCoachMark",
  // No feature flag is needed to show this coach mark.
  featureFlag: undefined,
};

export interface HomeState {
  showIosUpdateAlert: boolean;
  showCoachMark?: string;
}

const initialState: HomeState = {
  showIosUpdateAlert: false,
  showCoachMark: undefined,
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    iosUpdateIsRequired(state) {
      state.showIosUpdateAlert = true;
    },
    dismissUpdateIosAlert(state) {
      state.showIosUpdateAlert = false;
    },
    setCoachMark(state, { payload }: PayloadAction<string | undefined>) {
      state.showCoachMark = payload;
    },
  },
});

export const {
  reducer: homeReducer,
  actions: { iosUpdateIsRequired, dismissUpdateIosAlert, setCoachMark },
} = homeSlice;
