import { all } from "redux-saga/effects";

import barcodeScannerSagas from "../../features/barcode-scanner/barcodeScannerSagas";
import errorSagas from "../../features/error/errorSagas";
import homeSagas from "../../features/home/homeSagas";
import installModeSagas from "../../features/install-mode/installModeSagas";
import inventoryLocationSagas from "../../features/inventory-location/inventoryLocationSagas";
import itemInformationSagas from "../../features/item-information/itemInformationSagas";
import itemSearchSagas from "../../features/item-search/itemSearchSagas";
import itemStockSagas from "../../features/item-stock/itemStockSagas";
import { purchaseHoldSagas } from "../../features/purchase-hold/purchaseHoldSagas";
import routingSagas from "../../features/routing/routingSagas";
import stockReplenishmentSagas from "../../features/stock-replenishment/stockReplenishmentSagas";
import stockTakingSagas from "../../features/stock-taking/stockTakingSagas";
import { Saga } from "../../types";
import appSagas from "../appSagas";
import { ServiceContainer } from "../serviceContainer";

export default function* rootSaga(
  serviceContainer: ServiceContainer
): Saga<any> {
  yield all([
    appSagas(serviceContainer),
    barcodeScannerSagas(serviceContainer),
    errorSagas(),
    homeSagas(serviceContainer),
    installModeSagas(serviceContainer),
    inventoryLocationSagas(serviceContainer),
    itemInformationSagas(serviceContainer),
    itemSearchSagas(serviceContainer),
    itemStockSagas(serviceContainer),
    purchaseHoldSagas(serviceContainer),
    routingSagas(serviceContainer),
    stockReplenishmentSagas(serviceContainer),
    stockTakingSagas(serviceContainer),
  ]);
}
