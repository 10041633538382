/* eslint-disable no-restricted-globals */
import {
  ConfigureOptions,
  GetParsedResult,
  GetOptions,
  GetResult,
  RemoveOptions,
  SetAndStringifyOptions,
  SetOptions,
  StorageService,
} from "@ahlsell-group/store20-service-core";
import { Preferences } from "@capacitor/preferences";

/**
 * Uses Capacitor Preferences API to store key-value pairs for different platforms.
 *
 * Since capacitor uses a prefix when storing the keys, this class contains a
 * migration from no prefix to the capacitor default prefix.
 *
 * The source code for this Capacitor plugin can be found here:
 * https://github.com/ionic-team/capacitor-plugins/blob/main/preferences
 *
 * @todo The migration functionality should be removed in due time.
 */
export default class CapacitorStorageService implements StorageService {
  private async migrateToCapacitorPrefix(key: string, value: string) {
    // Add the new item with the Capacitor prefix.
    await Preferences.set({ key, value });

    // Remove the old item without prefix.
    localStorage.removeItem(key);
  }

  async configure(options: ConfigureOptions): Promise<void> {
    await Preferences.configure(options);
  }

  async get(options: GetOptions): Promise<GetResult> {
    const { value } = await Preferences.get(options);

    if (value !== null) {
      return {
        value,
      };
    }

    const localStorageItem = localStorage.getItem(options.key);
    if (localStorageItem !== null) {
      await this.migrateToCapacitorPrefix(options.key, localStorageItem);
    }

    return {
      value: localStorageItem ?? undefined,
    };
  }

  async getKeys(): Promise<string[]> {
    const localStorageKeys = Object.keys(localStorage);
    const { keys: preferencesKeys } = await Preferences.keys();

    return [...localStorageKeys, ...preferencesKeys];
  }

  async getAndParse<T>(options: GetOptions): Promise<GetParsedResult<T>> {
    const { value } = await this.get(options);

    try {
      return {
        value: value !== undefined ? (JSON.parse(value) as T) : undefined,
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Cant parse value ${value}.`, e);
      return {
        value: undefined,
      };
    }
  }

  async set(options: SetOptions): Promise<void> {
    await Preferences.set(options);
  }

  async setAndStringify<T>({
    key,
    value,
  }: SetAndStringifyOptions<T>): Promise<void> {
    if (typeof value === "string") {
      throw new Error(`Use ${this.set.name} for storing strings.`);
    }
    await this.set({ key, value: JSON.stringify(value) });
  }

  async remove(options: RemoveOptions): Promise<void> {
    await Preferences.remove(options);
    localStorage.removeItem(options.key);
  }
}
