// These user IDs and customer IDs are used by the webshop. We should have
// our own.
import { User } from "@ahlsell-group/store20-authentication-service";
import { ApiContext } from "@ahlsell-group/store20-service-core";

const contexts: Record<string, ApiContext> = {
  // 10: Ahlsell Sverige
  10: {
    site: "www.ahlsell.se",
    userId: "MjM1OTI0MDcxMw==", // 2359240713
    customerId: "333305",
  },
  // 10: Ahlsell Workwear
  "10_AWW": {
    site: "www.ahlsellworkwear.se",
    userId: "d2VidXNyMTA1", // webusr105
    customerId: "3333AWW",
  },
  // 15: Prevex
  15: {
    site: "shop.prevex.se",
    userId: "cHJldmJhY2tncm91bmQ=", // prevbackground
    customerId: "1500330",
  },
  // 31: Ahlsell Åland
  31: {
    site: "www.ahlsell.ax",
    userId: "QWxhbmRfYmFja1Vzcg==", // Aland_backUsr
    customerId: "333331",
  },
  // 32: Ahlsell Danmark
  32: {
    site: "www.ahlsell.dk",
    userId: "MjM1OTI1NDcwNQ==", // 2359254705
    customerId: "880001",
  },
  // 33: Ahlsell Norge
  33: {
    site: "www.ahlsell.no",
    userId: "MjM1OTI0NzMxMA==", // 2359247310
    customerId: "850001",
  },
  // 34: Ahlsell Finland
  34: {
    site: "www.ahlsell.fi",
    userId: "MjM1OTI1Mzg1MA==", // 2359253850
    customerId: "344445",
  },
};

const getApiContextForUser = (user: User) => {
  const isAwwEmployee =
    user.legalEntityId === 10 && user.email?.endsWith("ahlsellworkwear.se");

  if (isAwwEmployee) {
    return contexts["10_AWW"];
  }

  return contexts[user.legalEntityId] ?? contexts[10];
};

export { getApiContextForUser, contexts };
