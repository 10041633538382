import { combineReducers, Reducer } from "redux";
import { router5Reducer } from "redux-router5";

import { backgroundUpdateReducer } from "../../features/background-update/backgroundUpdateSlice";
import { barcodeScannerReducer } from "../../features/barcode-scanner/barcodeScannerSlice";
import { configReducer } from "../../features/config/configSlice";
import { errorReducer } from "../../features/error/errorSlice";
import { gettingStartedReducer } from "../../features/getting-started/gettingStartedSlice";
import { homeReducer } from "../../features/home/homeSlice";
import { installModeReducer } from "../../features/install-mode/installModeSlice";
import { inventoryLocationReducer } from "../../features/inventory-location/inventoryLocationSlice";
import { itemInformationReducer } from "../../features/item-information/itemInformationSlice";
import { itemSearchReducer } from "../../features/item-search/itemSearchSlice";
import { itemStockReducer } from "../../features/item-stock/itemStockSlice";
import { nearbyStoresReducer } from "../../features/item-stock/nearbyStoresSlice";
import { purchaseHoldReducer } from "../../features/purchase-hold/purchaseHoldSlice";
import { stockReplenishmentReducer } from "../../features/stock-replenishment/stockReplenishmentSlice";
import { manualStockTakingReducer } from "../../features/stock-taking/manualStockTakingSlice";
import { stockTakenSubmissionReducer } from "../../features/stock-taking/stockTakenSubmissionSlice";
import { warehouseReducer } from "../../features/warehouse/warehouseSlice";
import { State } from "../../types";
import { appReducer } from "../appSlice";

export default combineReducers<State>({
  // Third-party reducers
  router: router5Reducer as Reducer, // Cast because type is not exported from router5

  // Our reducers
  app: appReducer,
  backgroundUpdate: backgroundUpdateReducer,
  barcodeScanner: barcodeScannerReducer,
  config: configReducer,
  error: errorReducer,
  gettingStarted: gettingStartedReducer,
  home: homeReducer,
  installMode: installModeReducer,
  inventoryLocation: inventoryLocationReducer,
  itemInformation: itemInformationReducer,
  itemSearch: itemSearchReducer,
  itemStock: itemStockReducer,
  manualStockTaking: manualStockTakingReducer,
  nearbyStores: nearbyStoresReducer,
  purchaseHold: purchaseHoldReducer,
  stockReplenishment: stockReplenishmentReducer,
  stockTakenSubmission: stockTakenSubmissionReducer,
  warehouse: warehouseReducer,
});
