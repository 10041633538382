import i18n, {
  I18nLocalizationService,
  LocalizationService,
} from "@ahlsell-group/app-localization";
import {
  AuthenticationService,
  MsalAuthenticationService,
  NullAuthenticationService,
  CapacitorAuthenticationService,
} from "@ahlsell-group/store20-authentication-service";
import * as ConfigService from "@ahlsell-group/store20-config-service";
import {
  AxiosInventoryLocationService,
  InventoryLocationService,
} from "@ahlsell-group/store20-inventory-location-service";
import {
  AxiosInventoryService,
  InventoryService,
} from "@ahlsell-group/store20-inventory-service";
import { ProductDataHubLanguageService } from "@ahlsell-group/store20-item-language-service";
import {
  AggregateLogService,
  ApplicationInsightsLogger,
  ConsoleLogger,
  LogService,
} from "@ahlsell-group/store20-log-service";
import {
  AxiosItemSearchService,
  AxiosItemService,
  AxiosProductDataHubService,
  ItemSearchService,
  ItemService,
} from "@ahlsell-group/store20-product-service";
import {
  ApiContextService,
  ConfigManager,
  StorageService,
} from "@ahlsell-group/store20-service-core";
import {
  AxiosStockReplenishmentService,
  StockReplenishmentService,
} from "@ahlsell-group/store20-stock-replenishment-service";
import {
  AxiosStockTakingService,
  StockTakingService,
} from "@ahlsell-group/store20-stock-taking-service";
import {
  AxiosStoreSearchService,
  AxiosStoreService,
  StoreSearchService,
  StoreService,
} from "@ahlsell-group/store20-store-service";
import { Capacitor } from "@capacitor/core";

import CapacitorScanningService from "../features/barcode-scanner/CapacitorScanningService";
import E2eScanningContextService from "../features/barcode-scanner/E2eScanningContextService";
import ScanningContextService from "../features/barcode-scanner/ScanningContextService";
import WebScanningContextService from "../features/barcode-scanner/WebScanningContextService";
import { BarcodeScannerServiceMockImpl } from "../features/barcode-scanning/__tests__/mocks/BarcodeScannerServiceMock";
import {
  BarcodeScannerService,
  BarcodeScannerService2Impl,
} from "../features/barcode-scanning/BarcodeScannerService";
import handleSagaErrorFactory, {
  HandleSagaErrorFn,
} from "../features/error/sagas/handleSagaError";
import AppInstallationService from "../features/install-mode/AppInstallationService";
import {
  ItemSearchInputModeService,
  ItemSearchInputModeServiceImplementation,
} from "../features/item-search/ItemSearchInputModeService";
import router from "../features/routing/router";
import RoutingService, {
  RoutingServiceImplementation,
} from "../features/routing/RoutingService";
import NullServiceWorkerService from "../features/service-worker/NullServiceWorkerService";
import ServiceWorkerService, {
  IServiceWorkerService,
} from "../features/service-worker/ServiceWorkerService";

import StoreAccessor from "./redux/StoreAccessor";
import CapacitorStorageService from "./services/CapacitorStorageService";
import ItemServiceProxy from "./services/ItemServiceProxy";
import {
  NativeAppService,
  NativeAppServiceImpl,
} from "./services/NativeAppService";
import ReduxPersistenceServiceImpl, {
  ReduxPersistenceService,
} from "./services/ReduxPersistenceService";
import TenantScopeTokenServiceWrapper from "./services/TenantScopeTokenServiceWrapper";
import {
  AppTelemetryService,
  AppTelemetryServiceImpl,
  nullAppTelemetryService,
} from "./telemetry/AppTelemetryService";

export interface ServiceContainer {
  apiContextService: ApiContextService;
  appConfigService: ConfigService.AppConfigService;
  appInstallationService: AppInstallationService;
  appTelemetryService: AppTelemetryService;
  authenticationService: AuthenticationService;
  barcodeScannerService: BarcodeScannerService;
  configManager: ConfigManager<BootstrapConfig>;
  configService: ConfigService.ConfigService;
  handleSagaError: HandleSagaErrorFn;
  inventoryLocationService: InventoryLocationService;
  inventoryService: InventoryService;
  itemSearchInputModeService: ItemSearchInputModeService;
  itemSearchService: ItemSearchService;
  itemService: ItemService;
  localizationService: LocalizationService;
  logService: LogService;
  nativeAppService: NativeAppService;
  reduxPersistenceService: ReduxPersistenceService;
  routingService: RoutingService;
  scanningContextService: ScanningContextService;
  serviceWorkerService: IServiceWorkerService;
  stockTakingService: StockTakingService;
  stockReplenishmentService: StockReplenishmentService;
  storageService: StorageService;
  storeAccessor: StoreAccessor;
  storeSearchService: StoreSearchService;
  storeService: StoreService;
}

const getAuthenticationService = (
  configManager: ConfigManager<BootstrapConfig>
) => {
  const config = configManager.getConfig();

  if (window.Cypress) {
    return new NullAuthenticationService();
  }

  if (
    import.meta.IS_DEV_MODE &&
    sessionStorage.getItem("DISABLE_AUTH") === "1"
  ) {
    return new NullAuthenticationService();
  }

  if (Capacitor.isNativePlatform()) {
    return new CapacitorAuthenticationService(
      config.msalClientId,
      config.msalAuthority,
      config.msalTenant,
      config.msalSignatureHash ?? ""
    );
  }

  return new MsalAuthenticationService(
    config.msalClientId,
    config.msalAuthority,
    config.msalKnownAuthority,
    config.msalRedirectUrl
  );
};

function getScanningContextService(
  configManager: ConfigManager<
    RequiredBootstrapConfig & Required<DynamicBootstrapConfig>
  >
) {
  if (window.Cypress) {
    return new E2eScanningContextService();
  }
  if (Capacitor.isNativePlatform()) {
    return new CapacitorScanningService(
      configManager.getConfig().scanditLicense
    );
  }

  return new WebScanningContextService(
    configManager.getConfig().scanditLicense
  );
}

function getAppTelemetryService(
  configManager: ConfigManager<BootstrapConfig>,
  appInstallationService: AppInstallationService
): AppTelemetryService {
  if (window.Cypress) {
    return nullAppTelemetryService;
  }

  return new AppTelemetryServiceImpl(configManager, {
    appVersion: configManager.getConfig().versionDisplay,
    viewport: [window.innerWidth, window.innerHeight],
    pwaMode: appInstallationService.isInstalled() ? "standalone" : "browser",
  });
}

export const createServiceContainer = (
  configManager: ConfigManager<BootstrapConfig>
): ServiceContainer => {
  const authenticationService = getAuthenticationService(configManager);
  const appInstallationService = new AppInstallationService();

  const appTelemetryService = getAppTelemetryService(
    configManager,
    appInstallationService
  );
  const tenantScopeWrapper = new TenantScopeTokenServiceWrapper(
    authenticationService,
    configManager.getConfig().msalTenant
  );
  const capacitorStorageService = new CapacitorStorageService();
  const apiContextService = new ApiContextService();
  const itemService = new AxiosItemService(
    tenantScopeWrapper,
    apiContextService,
    configManager
  );
  const logService = new AggregateLogService([
    new ConsoleLogger(),
    new ApplicationInsightsLogger(
      appTelemetryService.applicationInsightsProvider?.applicationInsights
    ),
  ]);
  const storeAccessor = new StoreAccessor();
  const localizationService = new I18nLocalizationService(i18n);
  const itemSearchService = new AxiosItemSearchService(
    tenantScopeWrapper,
    apiContextService,
    configManager,
    itemService
  );
  const itemLanguageService = new ProductDataHubLanguageService(
    apiContextService,
    localizationService
  );
  const itemServiceProxy = new ItemServiceProxy(
    storeAccessor,
    itemService,
    itemSearchService,
    new AxiosProductDataHubService(
      tenantScopeWrapper,
      apiContextService,
      configManager,
      itemLanguageService
    )
  );

  return {
    apiContextService,
    appInstallationService,
    authenticationService,
    barcodeScannerService: window.Cypress
      ? new BarcodeScannerServiceMockImpl()
      : new BarcodeScannerService2Impl(
          configManager.getConfig().scanditLicense
        ),
    configService: new ConfigService.AxiosConfigService(
      tenantScopeWrapper,
      configManager
    ),
    logService,
    itemSearchService: itemServiceProxy,
    itemSearchInputModeService: new ItemSearchInputModeServiceImplementation(
      capacitorStorageService
    ),
    routingService: new RoutingServiceImplementation(router),
    stockTakingService: new AxiosStockTakingService(
      tenantScopeWrapper,
      configManager,
      itemLanguageService
    ),
    itemService: itemServiceProxy,
    stockReplenishmentService: new AxiosStockReplenishmentService(
      tenantScopeWrapper,
      configManager
    ),
    storeService: new AxiosStoreService(
      tenantScopeWrapper,
      apiContextService,
      configManager
    ),
    storageService: capacitorStorageService,
    serviceWorkerService:
      import.meta.IS_DEV_MODE || configManager.getConfig().env === "e2e"
        ? new NullServiceWorkerService()
        : new ServiceWorkerService(
            "/service-worker.js",
            configManager.getConfig().updateIntervalMinutes
          ),
    handleSagaError: handleSagaErrorFactory(logService),
    inventoryService: new AxiosInventoryService(
      tenantScopeWrapper,
      apiContextService,
      configManager
    ),
    configManager,
    storeAccessor,
    appTelemetryService,
    appConfigService: new ConfigService.LocalStorageAppConfigService(
      capacitorStorageService
    ),
    reduxPersistenceService: new ReduxPersistenceServiceImpl(
      capacitorStorageService
    ),
    localizationService: new I18nLocalizationService(i18n),
    inventoryLocationService: new AxiosInventoryLocationService(
      tenantScopeWrapper,
      configManager,
      capacitorStorageService
    ),
    scanningContextService: getScanningContextService(configManager),
    storeSearchService: new AxiosStoreSearchService(
      tenantScopeWrapper,
      apiContextService,
      configManager
    ),
    nativeAppService: new NativeAppServiceImpl(),
  };
};
