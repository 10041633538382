import { Selector } from "../../types";

import {
  StockReplenishmentOrderLinesKey,
  StockReplenishmentOrderLinesWithState,
  stringifyStockReplenishmentOrderLinesKey,
} from "./stockReplenishmentSlice";

export const selectStockReplenishmentOrderLines =
  (
    key: StockReplenishmentOrderLinesKey
  ): Selector<StockReplenishmentOrderLinesWithState | undefined> =>
  (state) =>
    state.stockReplenishment.orderLines[
      stringifyStockReplenishmentOrderLinesKey(key)
    ];
