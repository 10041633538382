import { Capacitor } from "@capacitor/core";

const getConfigLocal = (): RequiredBootstrapConfig => {
  // Change this to run against another API environment when debugging locally.
  const apiEnv: keyof typeof envSpecific = "localMock";

  const envSpecific = {
    localMock: {
      apiBaseUrl: "http://localhost:3001",
      apiSubscriptionKey: "e74356d570734e0c952167eace52fed1;product=store20",
    },
    devApim: {
      apiBaseUrl: "https://api.dev.azure.ahlsell.com",
      apiSubscriptionKey: "4731021553db47bbb8cf0b2094e7c3dd;product=store20",
    },
    testApim: {
      apiBaseUrl: "https://api.test.azure.ahlsell.com",
      apiSubscriptionKey: "0263042b2fe946968c24a5b98deefc44;product=store20",
    },
  };

  document.title = "(local) Ace";

  return {
    ...envSpecific[apiEnv],
    applicationInsightsConnectionString:
      "InstrumentationKey=796aac07-af6e-4966-862a-670a5e38d9e1;IngestionEndpoint=https://swedencentral-0.in.applicationinsights.azure.com/;ApplicationId=94342ed1-4816-42eb-95d8-ff4b9117654a",
    env: "local",
    imageBaseUrl: "https://assets.dev.azure.ahlsell.com",
    logRocketAppId: "wjcorr/ace-dev",
    msalAuthority:
      "https://ahlsellemployeedev.b2clogin.com/ahlsellemployeedev.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN",
    msalClientId: "70abbdce-d7c0-4439-a8f4-0725728d59ba",
    msalKnownAuthority: "ahlsellemployeedev.b2clogin.com",
    msalTenant: "ahlsellemployeedev.onmicrosoft.com",
    scanditLicense:
      "AXHlVAphIjwNBK0avAfI5FMHc/LR0OGa+we+Pp5wvZMvUTR4pmZTr81dATGGCPnj40iT1QFBOABcWPCmZ2AuBEhWQ3/PIM3UxEZ/Y8YL26O+d4634lpUW78l4Id3IYid3DxFCc4eJnLJaEElZitLb85ip6abXbkJ31rJ0G4zbIpeOT5j2Cw4JEIawrzyCZKQJDHd6kfEPrRWlTgE9YxHK57JVkhXMlTLS7fV9frRz+TYGVqJ1jySGDe8uwM9oFhOVFXctwSPCfMq5IsUXTB5JSUzzfbat637b6E2p1kg0EKbojjWP0MCWMaqT4E/pk0+ncfl99DXVap5wIg7g4+46UKMzMrv+XEd/2+d4XFNCvsgKZhCtQ1BloY7qz4pr/DjCYqscQsnzrb5aAjxCe1kZN1X8gp2oGWcc6qhpCDDLyR5Fmr9oHyuKhSSf4gnidaRUfXRZS2KWQyiAzBeGqLudle/PRmApqWJRH7k1mUistgDNul3SG6QpOh3L1dHf6i5AIfvxdLlcsEkSpeAhfN6067PpxoMblOEEzJtZOZbSVXVqclBpgPZq0jZGYIxYcdI2u4wiw9HJRhu9LWBtk/sRNtWpawqgB1NK7jHLMuTepFhQs81EER9MrUBA8rji3xDVps7V4HFjHy9hfJQzUaHbM9JWZ5AEL4bo77XEWvG0lIQ6ufm83sJ4fmN7kLYDfKPKqRhJvi6xVuGjp4yTwvGRm9zicW4E83CEyKaR3DSOa5E+Z8oWAmuBSwhdvnHG6fnWfM8G1dGVWvRtrGhiEe7isgf06smDIkDw3B9QRDHolplaTY9BzXeLsfVts/JYUiETVagFjTo52X6sj+/+OjaSctA4QvGPRbNmYZdMSBm/w==",
    updateIntervalMinutes: 1,
    version: "0.0.0",
    versionSha: "x",
    deployDateTime: "2021-09-27T09:53:35Z", // Time of initial commit
  };
};

const getNativeConfigLocal = (): RequiredBootstrapConfig &
  OptionalBootstrapConfig => {
  // Change this to run against another API environment when debugging locally.
  const apiEnv: keyof typeof envSpecific = "devMock";

  const envSpecific = {
    devMock: {
      apiBaseUrl: "https://mock-server.ace.dev.azure.ahlsell.com",
      apiSubscriptionKey: "e74356d570734e0c952167eace52fed1;product=store20",
    },
    devApim: {
      apiBaseUrl: "https://api.dev.azure.ahlsell.com",
      apiSubscriptionKey: "4731021553db47bbb8cf0b2094e7c3dd;product=store20",
    },
    testApim: {
      apiBaseUrl: "https://api.test.azure.ahlsell.com",
      apiSubscriptionKey: "0263042b2fe946968c24a5b98deefc44;product=store20",
    },
  };

  document.title = "(local) Ace";

  return {
    ...envSpecific[apiEnv],
    applicationInsightsConnectionString:
      "InstrumentationKey=796aac07-af6e-4966-862a-670a5e38d9e1;IngestionEndpoint=https://swedencentral-0.in.applicationinsights.azure.com/;ApplicationId=94342ed1-4816-42eb-95d8-ff4b9117654a",
    env: "local",
    imageBaseUrl: "https://assets.dev.azure.ahlsell.com",
    logRocketAppId: "wjcorr/ace-dev",
    msalAuthority:
      "https://ahlsellemployeedev.b2clogin.com/ahlsellemployeedev.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN",
    msalClientId: "70abbdce-d7c0-4439-a8f4-0725728d59ba",
    msalKnownAuthority: "ahlsellemployeedev.b2clogin.com",
    msalTenant: "ahlsellemployeedev.onmicrosoft.com",
    msalSignatureHash: import.meta.DEV_ONLY_MSAL_SIGNATURE_HASH,
    scanditLicense:
      "AQI0ridNAwoeKA6KZgDtBjEmMbJBFJEpvlgAnyF58lwETCSGOVJF1ZNvDUYiBSL1i01yCjxNJjKmfTFwgnsJrCd1f8mgEgLhQjfCs/k2mW9fMS7nph5WN/ZIBtdGH4Mo3yOZsIOFoUuzLWb8f8aod2cP0v7GUqWMeKgvKhel8BXQ2uNlhKLQ+6pGiiUo/QNIqW1MFJbg0PgByl8obBL9MhJjE7qxd3l/lca6z6nl+FMS/pjSZxxHDF1LFghSI2x+8EqvKafRW0wmAfcJCEqoucNnVNypo81VlQYuFMCbnpe+zeuS7Gn3nHyCZ7xLKpSE2XEbNg2DDt8PhaZc8l+7iFEodnfs3lJ0ZnXQjPlM7YtXwJGXODu+Y+o1+szd95AOXFrEd7OuGZEfVEb+coHApR0vs342uAIZZSYxRndVaZO/8BVGwXR+KvV16E7XqF36XX8AHZVJx+oPKVt8eK66VUr5TgDSO718X3Aq3dGl87L8h8Ms+ciI3qTExjZedWdOkOquFunou0VFJrk21ZIj8rLCweSBFdnfCvdd8dxyX2Lh4AJCMFy7CCp8xTnkMD2Ezk/e5c9uCRmdyL3rRlDM3FF5c+Tpa5nI64OBGLxqWy8rZTfv2OF3nKS6uqPLxWkDh8NXbAP9OpliFluE3im2KiwxQJUkOOw3wcH3WHM/V6GHQLQnnBwXK0R8akbujHAXEtEuo7AarPUUhcOwWp5P+q4lzB0qOw5tyif7BgITKTg9fa2JdFTyePF0zgsgeoZeXiNCvYl6t7bZlSvYDgtjBtI5BNsdXWNM",
    updateIntervalMinutes: 1,
    version: "0.0.0",
    versionSha: "x",
    deployDateTime: "2021-09-27T09:53:35Z", // Time of initial commit
  };
};

const getConfig = (): BootstrapConfig => {
  if (import.meta.IS_DEV_MODE) {
    window.s20 = Capacitor.isNativePlatform()
      ? getNativeConfigLocal()
      : getConfigLocal();
  }
  const { s20 } = window;

  const defaultOptionalValues: Required<DynamicBootstrapConfig> = {
    msalRedirectUrl: window.location.origin,
    versionDisplay:
      s20.env === "prd"
        ? s20.version
        : `${s20.env}-${s20.versionSha.slice(0, 7)}-${s20.deployDateTime}`,
  };

  return {
    ...defaultOptionalValues,
    ...window.s20,
  };
};

export default getConfig;
