import { useTranslation } from "@ahlsell-group/app-localization";
import {
  ArrowRightIcon,
  SearchIcon,
} from "@ahlsell-group/ui-kit-imagery-react";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import { Button } from "@ahlsell-group/ui-kit/inputs";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import BarcodeScannerModal from "../../barcode-scanner/components/BarcodeScannerModal";
import {
  CameraError,
  OnBarcodesScannedHandler,
} from "../../barcode-scanner/types";
import ItemSearch from "../../item-search/components/ItemSearch";
import RouteLink from "../../routing/components/RouteLink";
import View from "../../routing/components/View";
import routes from "../../routing/routes";
import useNavigate from "../../routing/useNavigate";
import { selectInventoryLocationItems } from "../inventoryLocationSelectors";
import { itemRequired, itemScanned } from "../inventoryLocationSlice";

import InventoryLocationItemCard from "./InventoryLocationItemCard";
import InventoryLocationNotification from "./InventoryLocationNotification";

const InventoryLocationScan: React.FC = function () {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const items = useSelector(selectInventoryLocationItems);

  const handleScan: OnBarcodesScannedHandler = useCallback(
    async (scanResult) => {
      scanResult[0].data &&
        dispatch(itemScanned({ barcode: scanResult[0].data }));
    },
    [dispatch]
  );

  const handleSearchSelect = async (itemId: string) => {
    const existingItem = items.find((x) => x.itemId === itemId);
    if (existingItem) {
      navigate(routes.inventoryLocation.change.items.item, { itemId });
    } else {
      dispatch(itemRequired({ itemId }));
      navigate(routes.inventoryLocation.change.items.new);
    }
  };

  let itemCards;
  if (items.length > 4) {
    itemCards = (
      <>
        {items.slice(0, 3).map((item) => (
          <InventoryLocationItemCard
            key={item.itemId}
            item={item}
            onClick={() =>
              navigate(routes.inventoryLocation.change.items.item, {
                itemId: item.itemId,
              })
            }
            cameraCard
          />
        ))}
        <InventoryLocationItemCard
          item={items[3]}
          onClick={() => navigate(routes.inventoryLocation.change.items)}
          overlay={{ show: true, value: items.length - 4 }}
          cameraCard
        />
      </>
    );
  } else if (items.length > 0) {
    itemCards = items.map((item) => (
      <InventoryLocationItemCard
        key={item.itemId}
        item={item}
        onClick={() =>
          navigate(routes.inventoryLocation.change.items.item, {
            itemId: item.itemId,
          })
        }
        cameraCard
      />
    ));
  } else {
    itemCards = null;
  }

  return (
    <>
      <View
        route={routes.inventoryLocation.change.scan}
        renderMode="mount"
        element={
          <BarcodeScannerModal
            onBarcodesScanned={handleScan}
            onClose={() => navigate.back()}
            cameraOverlayContent={<InventoryLocationNotification />}
            leftActionContent={
              <Typography variant="heading-h6">
                <RouteLink
                  variant="inline"
                  route={routes.inventoryLocation.change.itemSearch}
                  routeParams={{
                    mode: "id-only",
                  }}
                  icon={SearchIcon}
                  iconProps={{ size: "small" }}
                  className="text-theme-primary-light"
                  data-cy="scanner-item-search"
                >
                  {t("barcodeScanning.searchItem")}
                </RouteLink>
              </Typography>
            }
            extraContent={
              <div className="w-full flex flex-row mt-3 gap-1">{itemCards}</div>
            }
            rightActionContent={
              <Button
                variant="secondary"
                size="small"
                onClick={() => navigate.back()}
                className="w-full"
                icon={ArrowRightIcon}
                iconPlacement="end"
                data-cy="scanner-done"
              >
                {t("done")}
              </Button>
            }
            onError={(e?: CameraError) => {
              navigate(
                routes.inventoryLocation.change.itemSearch,
                { cameraError: e },
                { replace: true }
              );
            }}
          />
        }
        exact
      />
      <ItemSearch
        routes={routes.inventoryLocation.change}
        onSelect={handleSearchSelect}
        onNavigateToScanner={() =>
          navigate(routes.inventoryLocation.change.scan)
        }
      />
    </>
  );
};

export default InventoryLocationScan;
