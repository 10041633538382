import { FeatureFlag } from "@ahlsell-group/store20-config-service";
import { GetParsedResult } from "@ahlsell-group/store20-service-core";
import { call, put, select } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import { selectFeatureFlags } from "../../config/configSelectors";
import { CoachMark, setCoachMark } from "../homeSlice";

export default function* coachMarkSaga(
  coachMark: CoachMark,
  { storageService }: ServiceContainer
): Saga {
  const { value: isDismissed }: GetParsedResult<boolean> = yield call(
    [storageService, "getAndParse"],
    { key: coachMark.storageName }
  );
  const featureFlags: FeatureFlag[] = yield select(selectFeatureFlags);

  if (coachMark.featureFlag && !featureFlags.includes(coachMark.featureFlag)) {
    return;
  }

  if (!isDismissed) {
    yield put(setCoachMark(coachMark.name));
  }
}
