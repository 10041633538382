import { Item } from "@ahlsell-group/store20-stock-taking-service";

import { Selector } from "../../types";

import {
  CustomItemValidationError,
  GetOrCreateManualStockTakeError,
  ManualStockTakingState,
  ManualStockTakingStatus,
  SubmitStockTakesError,
  UpdateItemError,
  ValidationErrorRecord,
} from "./manualStockTakingSlice";

export const selectManualStockTakingStatus: Selector<
  ManualStockTakingStatus
> = (state) => state.manualStockTaking.status;

export const selectIsLoadingManualStockTake: Selector<boolean> = (state) =>
  state.manualStockTaking.initStockTakeState === "loading";

export const selectInitStockTakeError: Selector<
  GetOrCreateManualStockTakeError | undefined
> = (state) =>
  typeof state.manualStockTaking.initStockTakeState === "object"
    ? state.manualStockTaking.initStockTakeState
    : undefined;

export const selectManualStockTakingOpenItem: Selector<
  ManualStockTakingState["openItem"]
> = (state) => state.manualStockTaking.openItem;

export const selectItems: Selector<Item[]> = (state) =>
  state.manualStockTaking.items;

export const selectIsStockTakeInProgress: Selector<boolean> = (state) =>
  state.manualStockTaking.items.length !== 0;

export const selectSubmitStockTakesError: Selector<
  SubmitStockTakesError | undefined
> = (state) => state.manualStockTaking.submitStockTakesError;

export const selectCurrentStockTakeId: Selector<string | undefined> = (state) =>
  state.manualStockTaking.currentStockTakeId;

export const selectReviewState: Selector<
  ManualStockTakingState["reviewState"]
> = (state) => state.manualStockTaking.reviewState;

export const selectHasReviewValidationError: Selector<boolean> = (state) =>
  state.manualStockTaking.reviewState?.type === "validationError";

export const selectReviewValidationErrors: Selector<
  ValidationErrorRecord | undefined
> = (state) =>
  state.manualStockTaking.reviewState?.type === "validationError"
    ? state.manualStockTaking.reviewState.validationErrors
    : undefined;

export const selectItemReviewValidationError =
  (itemId: string): Selector<CustomItemValidationError | undefined> =>
  (state) =>
    selectReviewValidationErrors(state)?.[itemId];

export const selectManualStockTakingDeleteItemState: Selector<
  ManualStockTakingState["deleteItemState"]
> = (state) => state.manualStockTaking.deleteItemState;

export const selectManualStockTakingDeleteItemError: Selector<
  ManualStockTakingState["deleteItemError"] | undefined
> = (state) => state.manualStockTaking.deleteItemError;

export const selectManualStockTakingUpdateItemError: Selector<
  UpdateItemError | undefined
> = (state) => state.manualStockTaking.updateItemError;
